import React from 'react'
import Navbar from '../components/Navbar'
import { StaticImage } from 'gatsby-plugin-image'
import Footer from '../components/Footer'
import { Link } from "gatsby"
import SEO from '../components/SEO'


export default function thankYou() {
    return (
        <div>
            <SEO />
            <Navbar />
            <div className="has-background-dark has-text-centered columns">
                <div className="column"></div>
                <div className="column is-half">
                    <StaticImage 
                        src="../images/TopTalentLogo-green.png" 
                        alt="Top Talent and pyramid logo" 
                        width={100}
                    />
                    <h3 className="title has-text-primary">Thank you for your message!</h3>
                    <p className="subtitle has-text-primary pb-6">We will respond within 2 business days.</p>
                    <Link to="/" className="button has-background-warning">Return to Top Talent website</Link>
                </div>
                <div className="column"></div>
            </div>
            <Footer />
        </div>
    )
}
